<script>
import Navbar from "@/components/navbar";
import Service from "@/components/services";
import Nosotros from "@/components/nosotros";
//import Referidos from "@/components/referidos";
import Clients from "@/components/clients";
// import Pricing from "@/components/pricing";
// import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import { Carousel3d, Slide } from 'vue-carousel-3d';

/**
 * Index-1
 */
export default {
    components: { 
            Navbar, 
            Service, 
            Nosotros, 
            /*Referidos, Pricing, Blog,*/ 
            Clients, 
            Contact, 
            Footer, 
            /*Referidos*/ 
            Carousel3d,
            Slide
        },
    data: () => ({
    }),
    created() {
        console.log('Index Created!!!')
    },
    mounted() {
        console.log('Index Mounted!!!')
    }
};
</script>
<style>

    #home .prev, #home .next {
    color: #ffffff25 !important;
    }

    .carousel-3d-slide img {
        max-width: 100%; /* La imagen se ajustará al ancho máximo del div contenedor */
        height: auto; /* La altura se ajustará proporcionalmente */
    }
    
    .size-carousel{
        width: 1080px; 
        height: 520px;
    }
    
    /** Responsive */
    
    @media (max-width: 990px) {
        
        #mainSlide .carousel-3d-slide img {
            max-width: 60% !important; /* La imagen se ajustará al ancho máximo del div contenedor */
            height: auto; /* La altura se ajustará proporcionalmente */
        }
        #mainSlide .carousel-3d-slide h1 {
            font-size: 32px !important;
            text-align: center !important;
        }
        .carousel-3d-slide .font-size-17 {
            font-size: 14px !important;
        }
        .size-carousel{
            /*width: 90% !important;*/
            height: 580px !important;
        }
        #mainSlide .carousel-3d-slide{
            height: 580px !important;
        }
        .next {
            right: 0px;
        }
        .prev {
            right: 0px;
        }
        .texto-slide{
            padding-left: 40px;
            padding-right: 40px;
        }
        .hero-1-bg {
            padding: 90px 0 20px !important;
        }
    }
    
</style>
<template>
  <div>
    <Navbar />
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-1-bg"
        :style="{'background-image': 'url(' + require('@/assets/images/hero-1-bg-img.png') + ')'}"
        id="home"
      >
        <div class="container">
            <carousel-3d 
                id="mainSlide"
                :autoplay="true" 
                :autoplay-timeout="15000"
                :autoplayHoverPause="true"
                :controls-visible="true" 
                :controls-prev-html="'&#10092;'" 
                :controls-color="'#fff;'"
                :controls-next-html="'&#10093;'"
                :perspective="0" 
                :display="1"
                :width="1080" 
                :height="520"
                :border="0"
                class="size-carousel">
                <slide 
                    :index=0
                    key=1
                    style="background: transparent; overflow: hidden;">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-1 col-xs-1"></div>
                        <div class="col-lg-5 col-xs-2 col-md-10">
                            <div class="mt-4 mt-lg-0 mb-4">
                                <img src="@/assets/images/hero-1-img.png" alt class="img-fluid d-block mx-auto" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-xs-6">
                            <h1 class="hero-1-title text-light font-weight-bold text-shadow mb-4">Condominios Venezuela<br/></h1>
                            <div class="w-75 mb-5 mb-lg-0 texto-slide">
                                <p class="text-light mb-3 pb-3 font-size-17">
                                <b>Una plataforma adaptable y facíl de usar</b>  
                                <br/><br/>Ideal para...
                                <ul>
                                    <li>Condominios Residenciales</li>
                                    <li>Edificios Empresariales</li>
                                    <li>Centros Comerciales</li>
                                    <li>Entre otros...</li>
                                </ul>
                                </p>
                                <p class="text-muted">
                                <span class="font-size-20 mr-2">🥳️</span>¡¡¡Comienza desde hoy a ahorrar tiempo y esfuerzo!!!
                                </p>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide 
                    :index=1
                    key=2
                    style="background: transparent; overflow: hidden;">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-5">
                            <h1 class="hero-2-title text-light font-weight-bold text-shadow mb-4">Nueva interfaz...<br>Acorde a las nuevas tendencias y tecnólogias...</h1>
                            <p class="text-light mb-3 pb-3 font-size-17" >Asi es... Nuestro sitio se actualiza con increibles mejoras no solo estéticas, sino también en funcionalidades.</p>
                        </div>
                        <div class="col-lg-5 col-md-10">
                            <img src="@/assets/images/hero-3-img.png" alt class="img-fluid d-block mx-auto"/>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </slide>
                <slide 
                    :index=2
                    key=3
                    style="background: transparent; overflow: hidden;">
                    <div class="row justify-content-center">
                        <div class="col-xl-7 col-lg-10">
                        <div class="text-center">
                            <span class="badge badge-soft-primary text-uppercase">¡Proximamente!</span>
                            <h1
                            class="hero-2-title text-light font-weight-bold text-shadow mb-4 mt-4"
                            >Mantente atento a nuestras publicaciones y anuncios</h1>
                            <p class="text-light mx-auto mb-3 pb-3 font-size-17 texto-slide"
                            >Próximamente lanzaremos una serie de nuevas funcionalidades que elevarán aún más la eficiencia y la seguridad en la gestión de condominios.
                                <br/>
                                <br/>
                                <ul class="text-left">
                                    <li>Nuevas notificaciones SMS y Whatsapp</li>
                                    <li>Nueva App móvil con botón de pánico</li>
                                    <li>Función de control automatizado de accesos</li>
                                    <li>Sitios configurables por condominios</li>
                                    <li>Y muchas novedades mas...</li>
                                </ul>
                                <!-- <p class="text-muted">
                                <span class="font-size-20 mr-2">🥳️</span>¡¡¡Comienza desde hoy a ahorrar tiempo y esfuerzo!!!
                                </p> -->
                            </p>
                        </div>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div style="text-align: center;">
                <a href="javascript: void(0);" class="btn btn-primary" v-b-modal.login-modal>
                    Iniciar Sesión
                    <!-- <i class="mdi mdi-timer-sand mdi-spin ml-2"></i> -->
                </a>
            </div>
          <!-- <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="mt-5 mt-lg-0">
                <img src="@/assets/images/hero-1-img.png" alt class="img-fluid d-block mx-auto" />
              </div>
            </div>
            <div class="col-lg-6">
              <h1
                class="hero-1-title text-light font-weight-bold text-shadow mb-4"
              >Condominios Venezuela<br/></h1>
              <div class="w-75 mb-5 mb-lg-0">
                <p class="text-light mb-3 pb-3 font-size-17">
                  <b>Una plataforma adaptable y facíl de usar</b>  
                  <br/><br/>Ideal para...
                  <ul>
                    <li>Condominios Residenciales</li>
                    <li>Edificios Empresariales</li>
                    <li>Centros Comerciales</li>
                    <li>Entre otros...</li>
                  </ul>
                </p>
                <p class="text-muted">
                  <span class="font-size-20 mr-2">🥳️</span>¡¡¡Comienza desde hoy a ahorrar tiempo y esfuerzo!!!
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </section>
      <!-- Hero End -->
      <Nosotros />
      <Service />
      <!-- <Referidos /> -->
      <!-- <Pricing /> -->
      <Clients />
      <!-- <Blog /> -->
      <Contact />
      <Footer />
    </div>
  </div>
</template>