
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
    components: { 
        Carousel3d,
        Slide
    },
    data: () => ({
        slides: [
            /* {
                id: 1,
                img: require('@/assets/images/logos/logo-lapradera.jpg'),
                name: "Urbanización La Pradera",
                city: "Maturín, Estado - Monagas",
                email: "jcondominiolapradera@gmail.com"
            }, */
            {
                id: 1,
                img: require('@/assets/images/logos/logo-condominiofacil.jpg'),
                name: "Condominio Fácil, C.A.",
                city: "Maturín, Estado - Monagas",
                email: "jcondominiolapradera@gmail.com"
            },
            {
                id: 2,
                img: require('@/assets/images/logos/logo-clublomasdelbosque.jpg'),
                name: "Club Social y Deportivo Lomas del Bosque",
                city: "Maturín, Estado - Monagas",
                email: "www.lomasdelbosquecountryclub.com"
            },
            {
                id: 3,
                img: require('@/assets/images/logos/logo-jerahome.jpg'),
                name: "Jera Home Administradora, C.A",
                city: "Maturín, Estado - Monagas",
                email: "jerahomeadm@gmail.com"
            },
            {
                id: 4,
                img: require('@/assets/images/logos/logo-asinca.jpg'),
                name: "Asesoria Integral de Inmuebles, C.A",
                city: "Caracas, Estado - Miranda",
                email: "asinca@gmail.com"
            },
            {
                id: 5,
                img: require('@/assets/images/logos/logo-cristal.jpg'),
                name: "Administradora Cristal Carúpano, C.A",
                city: "Carúpano, Estado - Sucre",
                email: "admcristalcarupanoca@gmail.com"
            },
            {
                id: 6,
                img: require('@/assets/images/logos/logo-olivos.jpg'),
                name: "Urb. Los Olivos",
                city: "Maturín, Estado - Monagas",
                email: "asinca@gmail.com"
            },
            {
                id: 7,
                img: require('@/assets/images/logos/logo-zafrane.jpg'),
                name: "Zafrané Consultores, C.A",
                city: "Caracas, Estado - Miranda",
                email: "asinca@gmail.com"
            }
        ],
        colors: ['#ffcc55', '#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107;', '#28a745', '#6c757d']
    })

};
</script>
<template>
    <!-- Pricing Start -->
    <section class="section bg-light" id="clients">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h3 class="title mb-3">Nuestros Clientes</h3>
                    <p class="text-secondary font-size-15">
                        Para nosotros en www.condominiosvenezuela.com nuestros clientes son parte fundamental y son nuestra razón de ser. Nos esforzamos diariamente para brindar un servicio excepcional que aumente su productividad y les permita disfrutar de una experiencia inolvidable con nuestros servicios.
                        <br/>Por tal razón nos esforzamos dia a dia por superar sus espectativas y lograr que cada interacción con nosotros sea algo totalmente agradable y satisfactorio.
                        <br/><br/>Estos son solo algunos de los clientes que actualmente forman parte de nuestro team y estan disfrutando de nuestros servicios:
                    </p>
                </div>
                </div>
            </div>
            <div class="row">
                <carousel-3d 
                    :autoplay="true" 
                    :autoplay-timeout="5000"
                    :autoplayHoverPause="true"
                    :controls-visible="true" 
                    :display="3"
                    :width="380" 
                    :height="200"
                    :border="0">
                    <slide v-for="(slid, i) in slides" 
                        :index="i" 
                        :key="slid.id"
                        class="rounded" 
                        style="box-shadow: 0 3px 12px rgba(6, 11, 47, 0.15); overflow: hidden;">
                        <figure>
                            <img :src="`${slid.img}`" height="136">
                            <figcaption class="pb-2" style="background-color: rgba(245, 245, 253, 1);">
                                <div class="text-primary title ml-2">{{slid.name}}</div>
                                <div class="text-secondary font-weight-light font-size-12 wc-subtitle ml-2">
                                    {{slid.city}}
                                </div>
                                <div class="text-secondary font-weight-light font-size-12 wc-subtitle ml-2">
                                    {{slid.email}}
                                </div>
                            </figcaption>
                        </figure>
                       <!--  <div :style="`background-image: url(${slid.img})!important; background-position: center; background-repeat: no-repeat; height: 180px; width: 360px;`">
                            <div class="text-left" style="left: 0px; bottom: 0px; position: absolute; background-color: rgba(255, 255, 255, 0.75); width: 100%">
                                <div class="title ml-2">{{slid.name}}</div>
                                <div class="font-weight-light font-size-12 wc-subtitle ml-2">
                                    {{slid.city}}
                                </div>
                                <div class="font-weight-light font-size-12 wc-subtitle ml-2">
                                    {{slid.email}}
                                </div>
                            </div>
                        </div> -->
                    </slide>
                </carousel-3d>
            </div>
        </div>
    </section>
  </template>
  